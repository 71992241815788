<template>
  <div>
    <div v-if="units.length" class="hidden rounded-2xl border border-dm-50 md:block">
      <UiStickyColumnsTable :items="units" :columns="columns" class="pt-3" @clicked="emits('open-units')">
        <template #title="{ item }">
          <p class="text-subhead-4">{{ item.title }}</p>
        </template>
        <template #area_min="{ item }">
          <span>from {{ item.area_min.toFixed(0) }} m2</span>
        </template>
        <template #min_price_m2="{ item }">
          <span
            >from
            {{ getMinPriceM2(item.name) }}
          </span>
        </template>
        <template #price_min="{ item }">
          <span class="flex items-center"
            >from
            {{ aedFormatter.format(item.price_min) }}
            {{ item.currency }}
            <button class="p-1.5"><UiIcon name="chevron-small-right" class="!size-4 text-black-90" /></button>
          </span>
        </template>
      </UiStickyColumnsTable>
    </div>
    <div class="md:hidden">
      <client-only>
        <Swiper navigation :slides-per-view="1" :modules="[SwiperNavigation]">
          <SwiperSlide v-for="(unit, index) in units" :key="index">
            <div class="flex flex-col gap-2">
              <div class="flex flex-row justify-center">
                {{ unit.title }}
              </div>
              <div class="flex flex-col gap-2 rounded-xl border border-solid border-primary-20 p-3">
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="area" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">Area, M2</span>
                    <span class="text-body-2">{{ unit.area_min.toFixed(0) }}+ M2</span>
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="money-banknotes" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">Price per m2</span>
                    <span class="text-body-2">{{ getMinPriceM2(unit.name) }}+ M2</span>
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="treemap" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">№ of units</span>
                    <span class="text-body-2">{{ unit.count }}</span>
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="home" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">Unit price</span>
                    <span class="text-body-2">{{ aedFormatter.format(unit.price_min) }} {{ unit.currency }}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropertyTypes } from '@/types'

const emits = defineEmits(['open-units'])

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})

type Props = {
  propertyDetailed: PropertyTypes.PropertyDetailed
}

const props = defineProps<Props>()

const getMinPriceM2 = (name: string) => {
  const unit = props.propertyDetailed.br_prices.find((unit) => unit.key === name)
  if (!unit) return ' '
  return aedFormatter.format(Number(unit.min_price_m2)) + ' ' + unit.currency
}

const units = computed(() => {
  if (!props.propertyDetailed.apartments.length) return []
  return getPropertyUnits(props.propertyDetailed.apartments)
})

const columns = [
  { text: 'Property type', slot: 'title', value: 'title' },
  { text: 'ARea, M2', slot: 'area_min', value: 'area_min' },
  { text: 'Price per m2', slot: 'min_price_m2', value: 'min_price_m2' },
  { text: '№ of units', value: 'count' },
  { text: 'Unit price', slot: 'price_min', value: 'price_min' },
]
</script>

<style scoped></style>
